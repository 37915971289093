import { Box, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export const Footer = ({collapsed}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box
      height="50px"
      width="100%"
      bgcolor={theme.palette.footer.background}
      display="flex"
      justifyContent="center"
      alignItems="center"
      boxShadow={5}
    >
      <Typography mr={0.5} pl={(!collapsed && isSmallScreen) && 30}>Maintained by <Link href="https://xerzer.com" underline="none" color="inherit" target="_blank" rel="noopener noreferrer">Xerzer Developments</Link></Typography>
    </Box>
  );
};
