import { useState } from "react";
import { ColorModeContext, useMode } from "./theme";
import { Box, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";

import { Topbar } from "./global/Topbar";
import { Sidebar } from "./global/Sidebar";
import { Footer } from "./global/Footer";
import { FrontendRoutes } from "./routes/FrontendRoutes";

function App() {
  const [theme, colorMode] = useMode();
  const [logged, setLogged] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { pathname } = useLocation();

  // Sidebar collapse state
  const [collapsed, setCollapsed] = useState(true);
  const [menuClicked, setMenuClicked] = useState(false);
  const toggleCollapse = () => setCollapsed(!collapsed);

  return (
    <div style={{ overflow: "hidden" }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <div className="app">
            {logged && pathname !== "/login" && (
              <Sidebar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                menuClicked={menuClicked}
              />
            )}
            <main className="content">
              <Box
                width="100%"
                height="100%"
                sx={{ position: { xs: "absolute", md: "inherit" } }}
              >
                {logged && pathname !== "/login" && (
                  <Box>
                    <Topbar
                      toggleCollapse={toggleCollapse}
                      setLogged={setLogged}
                      setMenuClicked={setMenuClicked}
                    />
                  </Box>
                )}
                <Box
                  onClick={() => {
                    if (isSmallScreen) return setCollapsed(true);
                  }}
                  display="flex"
                  flexDirection="column"
                  minHeight={
                    pathname === "/login" ? "100%" : "calc(100% - 70px)"
                  }
                  height={pathname === "/login" ? "100%" : "calc(100% - 70px)"}
                  sx={{ overflowY: "auto", overflowX: "hidden" }}
                >
                  <Box flex={1}>
                    <Box
                      pb={5} // => Adjust gap between content and footer here
                      width="100%"
                      height="100%"
                      sx={{ overflowX: "auto", overflowY: "hidden" }}
                      px={4}
                      pt={2}
                    >
                      <FrontendRoutes logged={logged} setLogged={setLogged} />
                    </Box>
                  </Box>
                  {logged && pathname !== "/login" && (
                    <Box width="100%">
                      <Footer collapsed={collapsed} />
                    </Box>
                  )}
                </Box>
              </Box>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </div>
  );
}

export default App;
