import { Suspense, lazy } from "react";

import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "../auth/ProtectedRoute";

import { Spinner } from "../components/Spinner";
import { LoadingProvider } from "../contexts/LoaderContext";

const Login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));

const CustomerView = lazy(() => import("../pages/customer/View"));
const ProjectView = lazy(() => import("../pages/project/View"));
const ApplicationView = lazy(() => import("../pages/application/View"));
const ApplicationViewDetail = lazy(() =>
  import("../pages/application/ViewDetail")
);
const LuckyDrawView = lazy(() => import("../pages/luckydraw/View"));
const LuckyDrawView2 = lazy(() => import("../pages/luckydraw/View2"));
const AgentView = lazy(() => import("../pages/agent/View"));
const FormTypeView = lazy(() => import("../pages/form-type/View"));
const PlotFacingView = lazy(() => import("../pages/plot-facing/View"));
const PaymentModeView = lazy(() => import("../pages/payment-mode/View"));
const PaymentPlanView = lazy(() => import("../pages/payment-plan/View"));
const PlotSizeView = lazy(() => import("../pages/plot-size/View"));
const SiteVisitView = lazy(() => import("../pages/site-visit/View"));
const MobilePaymentView = lazy(() => import("../pages/mobile-payment/View"));
const HomeSettingView = lazy(() => import("../pages/home-settings/View"));
const ReferralsView = lazy(() => import("../pages/referrals/View"));
const NotificationView = lazy(() => import("../pages/notification/View"));
const MobileImageView = lazy(() => import("../pages/mobile-image/View"));
const MobileVideoView = lazy(() => import("../pages/mobile-video/View"));
const ResaleSubmission = lazy(() => import("../pages/resale/Submission"));
const ResaleSubmissionView = lazy(() => import("../pages/resale/SubmissionView"));
const ResaleAssistance = lazy(() => import("../pages/resale/Assistance"));

const CustomerCreate = lazy(() => import("../pages/customer/Create"));
const ProjectCreate = lazy(() => import("../pages/project/Create"));
const ApplicationCreate = lazy(() => import("../pages/application/Create"));
const AgentCreate = lazy(() => import("../pages/agent/Create"));
const FormTypeCreate = lazy(() => import("../pages/form-type/Create"));
const PlotFacingCreate = lazy(() => import("../pages/plot-facing/Create"));
const PaymentModeCreate = lazy(() => import("../pages/payment-mode/Create"));
const PaymentPlanCreate = lazy(() => import("../pages/payment-plan/Create"));
const PlotSizeCreate = lazy(() => import("../pages/plot-size/Create"));
const LuckyDrawCreate = lazy(() => import("../pages/luckydraw/Create"));
const MobileImageCreate = lazy(() => import("../pages/mobile-image/Create"));
const MobileVideoCreate = lazy(() => import("../pages/mobile-video/Create"));
const MobilePaymentCreate = lazy(() =>
  import("../pages/mobile-payment/Create")
);

const CustomerUpdate = lazy(() => import("../pages/customer/Update"));
const ProjectUpdate = lazy(() => import("../pages/project/Update"));
const ApplicationUpdateApplicant = lazy(() =>
  import("../pages/application/UpdateAppliant")
);
const LuckyDrawUpdate = lazy(() => import("../pages/luckydraw/Update"));
const AgentUpdate = lazy(() => import("../pages/agent/Update"));
const FormTypeUpdate = lazy(() => import("../pages/form-type/Update"));
const PlotFacingUpdate = lazy(() => import("../pages/plot-facing/Update"));
const PaymentModeUpdate = lazy(() => import("../pages/payment-mode/Update"));
const PaymentPlanUpdate = lazy(() => import("../pages/payment-plan/Update"));
const PlotSizeUpdate = lazy(() => import("../pages/plot-size/Update"));
const SiteVisitUpdate = lazy(() => import("../pages/site-visit/Update"));
const MobilePaymentUpdate = lazy(() =>
  import("../pages/mobile-payment/Update")
);

const AboutUs = lazy(() => import("../pages/AboutUs"));

export const FrontendRoutes = ({ logged, setLogged }) => {
  return (
    <Suspense fallback={<Spinner background="none" />}>
      <LoadingProvider>
        <Routes>
          <Route path="/login" element={<Login setLogged={setLogged} />} />
          <Route
            element={<ProtectedRoute logged={logged} setLogged={setLogged} />}
          >
            <Route path="/" exact element={<Dashboard />} />
            <Route path="project">
              <Route path="view" exact element={<ProjectView />} />
              <Route path="create" exact element={<ProjectCreate />} />
              <Route exact path="update/:id" element={<ProjectUpdate />} />
            </Route>

            <Route path="customer">
              <Route path="create" exact element={<CustomerCreate />} />
              <Route path="view" exact element={<CustomerView />} />
              <Route exact path="update/:id" element={<CustomerUpdate />} />
            </Route>

            <Route path="application">
              <Route path="view" exact element={<ApplicationView />} />
              <Route exact path="create/:id" element={<ApplicationCreate />} />
              <Route
                exact
                path="view/:id"
                element={<ApplicationViewDetail />}
              />
              <Route
                exact
                path="update/:id"
                element={<ApplicationUpdateApplicant />}
              />
            </Route>

            <Route path="luckydraw">
              <Route path="view" exact element={<LuckyDrawView />} />
              <Route path="create" exact element={<LuckyDrawCreate />} />
              <Route exact path="update/:id" element={<LuckyDrawUpdate />} />
              <Route path="view/:id" exact element={<LuckyDrawView2 />} />
            </Route>

            <Route path="agent">
              <Route path="view" exact element={<AgentView />} />
              <Route path="create" exact element={<AgentCreate />} />
              <Route path="update/:id" exact element={<AgentUpdate />} />
            </Route>

            <Route path="form-type">
              <Route path="view" exact element={<FormTypeView />} />
              <Route path="create" exact element={<FormTypeCreate />} />
              <Route exact path="update/:id" element={<FormTypeUpdate />} />
            </Route>

            <Route path="plot-facing">
              <Route path="view" exact element={<PlotFacingView />} />
              <Route path="create" exact element={<PlotFacingCreate />} />
              <Route exact path="update/:id" element={<PlotFacingUpdate />} />
            </Route>

            <Route path="payment-mode">
              <Route path="view" exact element={<PaymentModeView />} />
              <Route exact path="create" element={<PaymentModeCreate />} />
              <Route exact path="update/:id" element={<PaymentModeUpdate />} />
            </Route>

            <Route path="payment-plan">
              <Route path="view" exact element={<PaymentPlanView />} />
              <Route exact path="create" element={<PaymentPlanCreate />} />
              <Route exact path="update/:id" element={<PaymentPlanUpdate />} />
            </Route>

            <Route path="plot-size">
              <Route path="view" exact element={<PlotSizeView />} />
              <Route path="create" exact element={<PlotSizeCreate />} />
              <Route exact path="update/:id" element={<PlotSizeUpdate />} />
            </Route>

            <Route path="site-visit">
              <Route path="view" exact element={<SiteVisitView />} />
              <Route exact path="update/:id" element={<SiteVisitUpdate />} />
            </Route>

            <Route path="referrals">
              <Route path="view" exact element={<ReferralsView />} />
            </Route>

            <Route path="notification">
              <Route path="view" exact element={<NotificationView />} />
            </Route>

            <Route path="mobile-payment">
              <Route path="view" exact element={<MobilePaymentView />} />
              <Route
                path="update/:id"
                exact
                element={<MobilePaymentUpdate />}
              />
              <Route path="create" exact element={<MobilePaymentCreate />} />
            </Route>

            <Route path="home-settings" exact element={<HomeSettingView />} />

            <Route path="mobile-image">
              <Route path="view" exact element={<MobileImageView />} />
              <Route exact path="create" element={<MobileImageCreate />} />
            </Route>

            <Route path="mobile-video">
              <Route path="view" exact element={<MobileVideoView />} />
              <Route exact path="create" element={<MobileVideoCreate />} />
            </Route>

            <Route
              exact
              path="resale-assistance"
              element={<ResaleAssistance />}
            />
            <Route
              exact
              path="resale-submission"
              element={<ResaleSubmission />}
            />
            <Route
              exact
              path="resale-submission/view/:id"
              element={<ResaleSubmissionView />}
            />
            <Route exact path="/about" element={<AboutUs />} />
          </Route>
        </Routes>
      </LoadingProvider>
    </Suspense>
  );
};
